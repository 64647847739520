import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (
  <>
    <h1 className="mt0 mb3 mb4-l">
      <Link to="/" className="db home-link w4 w-auto-ns fade-in">
        <span className="clip">{siteTitle}</span>
        <img
          src={require('../images/gs-logo.svg')}
          className="db"
          alt="Gracescale logo."
        />
      </Link>
    </h1>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
